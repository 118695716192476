.Mui-selected {
  background-color: #a1cdfd !important;
  color: #000000;
}
.MuiTreeItem-label {
  color: #000000 !important;
}
option:hover {
  background-color: #a1cdfd !important;
}
option:checked {
  background-color: #a1cdfd !important;
}