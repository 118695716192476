.react-flow {

}

.react-flow__node-startingNode {
  font-size: 12px;
  background: #FFFFFF;
  padding: 20px;
  width: 200px;
  height: 110px;
  overflow-y: visible;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-actionNode {
  font-size: 12px;
  background: #FFFFFF;
  padding: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-inputNode {
  font-size: 12px;
  background: #FFFFFF;
  padding: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-outputNode {
  font-size: 12px;
  background: #FFFFFF;
  padding: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-subsystemNode {
  font-size: 12px;
  background: #FFFFFF;
  padding: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

