.directory {
  background: #ffffff;
  font-family: Arial;
  font-size: 14px;
  color: #242322;
  user-select: none;
  padding: 20px;
  border-radius: 0.4em;
}

.directory .tree,
.directory .tree-node,
.directory .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.directory .tree-branch-wrapper,
.directory .tree-node__leaf {
  outline: none;
  outline: none;
}

.directory .tree-node {
  cursor: pointer;
}

.directory .tree-node:hover {
  background: rgba(255, 255, 255, 0.1);
}

.directory .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
}

.directory .tree .tree-leaf-list-item--selected {
  background: rgba(1, 1, 24, 0.11);
}

.directory .tree .tree-node--selected {
  background: rgba(1, 1, 24, 0.11);
}

.directory .tree-node__branch {
  display: block;
}

.directory .icon {
  vertical-align: middle;
  padding-right: 5px;
}